










import { Component, Vue } from 'vue-property-decorator';

// Emits event 'click'

@Component({
  props: {
    icon: String,
    primaryColor: String, // 1, 2, 3, 4, 5 or grey
  },
})
export default class DataTableActionComponent extends Vue {
}
