






































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ is an alias to /src
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { Survey } from '@/store/modules/survey/types';
import { formatYmdString } from '@/utils/dateHelpers';
import Log from '@/utils/log';
import { SelectSurveyParams } from '@/store/actions';
import { Flavor } from '@/flavor/types';
import { flavor } from '@/flavor';

interface TableRow extends Survey {
  dateRange : string;
  detailsLoaded : boolean;
  endDateObj : Date;
}

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
    DataTableComponent,
  },
})
export default class SurveyView extends Vue {
  public tableData: Array<TableRow> = [];
  public selectedId: number|null = null; // id of selected row
  flavor: Flavor = flavor;
  travelViewer = Flavor.travelViewer;

  data() {
    const tableRows = this.$store.state.survey.surveyList.map((row:Survey) => {
      const r : any = { ...row };
      const start = formatYmdString(row.startDate, '', this.$i18n.locale);
      const end = formatYmdString(row.endDate, '', this.$i18n.locale);
      r.dateRange = `${start} - ${end}`;
      r.detailsLoaded = false;
      r.endDateObj = row.endDate !== null ? new Date(row.endDate) : null;
      return r;
    });

    const selectedSurvey = this.$store.getters['survey/getSelected'];
    const selectedRowId = selectedSurvey === null ? null : selectedSurvey.id;

    // Request loading of details if not yet loaded and set
    // detailsLoaded when completed
    /* eslint no-param-reassign: ["error", { "props": false }] */
    tableRows.forEach((row:TableRow) => {
      if (row.correctedDayInSurveyCount === undefined
        || row.participateCountAllTimeMinOneCorrDay === undefined
      ) {
        try {
          this.$store.dispatch('survey/loadDetails', {
            surveyId: row.id,
          }).then((details : Survey) => {
            row.participateCountAllTimeMinOneCorrDay = details.participateCountAllTimeMinOneCorrDay;
            row.correctedDayInSurveyCount = details.correctedDayInSurveyCount;
            row.detailsLoaded = true;
          });
        } catch (e) {
          // Do nothing
        }
      } else {
        row.detailsLoaded = true;
      }
    });

    return {
      selectedId: selectedRowId,
      tableData: tableRows,
    };
  }

  /* eslint class-methods-use-this: ["off"] */
  sortByDate(a: TableRow, b : TableRow, isAsc : boolean) {
    if (a.endDateObj === null && b.endDateObj === null) return 0;
    if (a.endDateObj === null) return isAsc ? -1 : 1;
    if (b.endDateObj === null) return isAsc ? 1 : -1;
    if ((a.endDate as string) < (b.endDate as string)) return isAsc ? -1 : 1;
    if ((a.endDate as string) > (b.endDate as string)) return isAsc ? 1 : -1;
    return 0;
  }

  onRowClick(row: TableRow) {
    this.persistSelection(row);
  }

  onRadioChange(row: TableRow) {
    this.persistSelection(row);
  }

  persistSelection(row : TableRow|null) {
    if (row !== null && typeof row.id === 'number') {
      this.selectedId = row.id;
      this.$store.dispatch('selectSurvey', {
        surveyId: row.id,
        startDate: row.startDate,
        endDate: row.endDate,
        restore: false,
      } as SelectSurveyParams).catch(() => {
        this.selectedId = -1;
      });
    }
  }

  onClear() {
    this.$store.commit('survey/selectSurvey', -1);
    this.selectedId = null;
  }

  onNext() {
    if (this.selectedId !== null) {
      this.$router.push('/sample');
    }
  }
}
