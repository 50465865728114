
















import { Component, Vue } from 'vue-property-decorator';
import DataTableActionComponent from './DataTableActionComponent.vue';

@Component({
  props: {
    title: String,
    showEditButton: Boolean,
    editButtonCallback: Function,
  },
  components: {
    DataTableActionComponent,
  },
})
export default class DataTableComponent extends Vue {
}
