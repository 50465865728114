import { render, staticRenderFns } from "./DataTableActionComponent.vue?vue&type=template&id=583da7da&scoped=true&"
import script from "./DataTableActionComponent.vue?vue&type=script&lang=ts&"
export * from "./DataTableActionComponent.vue?vue&type=script&lang=ts&"
import style0 from "./DataTableActionComponent.vue?vue&type=style&index=0&id=583da7da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583da7da",
  null
  
)

export default component.exports